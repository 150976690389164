<template>
  <div id="app">
    <el-container>
      <el-header>
        <navMenu :nav="nav" @navChange="navChange" />
      </el-header>
      <el-container>
        <el-aside class="main-aside" width="150px">
          <sideMenu :sideMenu="currentSideMenu" />
        </el-aside>
        <el-container>
          <el-main class="main-container">
            <keep-alive>
              <router-view v-if="$route.meta && $route.meta.keepAlive" />
            </keep-alive>
            <router-view v-if="!$route.meta || !$route.meta.keepAlive" />
          </el-main>
          <el-footer>
            <div class="main-footer">
              <span>Tools for Developers</span>
            </div>
          </el-footer>
        </el-container>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import navMenu from "./components/nav-menu.vue";
import sideMenu from "./components/side-menu.vue";
import { nav } from "./config/nav.json";

export default {
  name: "App",
  components: {
    navMenu,
    sideMenu,
  },
  data() {
    return {
      nav,
      currentSideMenu: [],
    };
  },
  methods: {
    navChange(nav) {
      this.currentSideMenu = nav.sideMenu;
    },
  },
};
</script>

<style>
body {
  margin: 0;
}
.el-header {
  padding: 0;
}
.main-aside {
  height: calc(100vh - 60px);
}
.main-container {
  height: calc(100vh - 120px);
}
.main-footer {
  text-align: center;
}
</style>
