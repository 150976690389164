<template>
  <div>
    <el-row>
      <el-col :span="3"
        ><div class="logo">
          <span>工具岛</span>
        </div></el-col
      >
      <el-col :span="21">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-dark"
          mode="horizontal"
          @select="handleSelect"
          background-color="#545c64"
          text-color="#fff"
          active-text-color="#ffd04b"
        >
          <el-menu-item v-for="n in nav" :key="n.navId" :index="n.navId">{{
            n.navName
          }}</el-menu-item>
        </el-menu>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { find } from "lodash";

export default {
  data() {
    return {
      activeIndex: "crypto",
    };
  },
  props: {
    nav: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    this.checkNav();
  },
  methods: {
    handleSelect(key, keyPath) {
      const nav = find(this.nav, (n) => n.navId === key);
      this.$emit("navChange", nav);
    },
    checkNav() {
      let r = location.hash.split("/").pop();

      let nav = find(this.nav, (n) => {
        return find(n.sideMenu, (m) => {
          return find(m.items, (i) => i.id === r);
        });
      });

      if (nav) {
        this.activeIndex = nav.navId;
        this.$emit("navChange", nav);
      } else {
        this.$emit("navChange", this.nav[0]);
      }
    },
  },
};
</script>
<style scoped>
.logo {
  height: 60px;
  color: white;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #545c64;
}
.el-menu-dark {
  padding-left: 10px;
}
</style>