import Vue from 'vue';
import Router from 'vue-router';
import { routers } from './router';

Vue.use(Router);

// 路由配置
const RouterConfig = {
  routes: routers,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve(savedPosition);
        }, 1000);
      });
    } else {
      if (from.meta.keepAlive) {
        from.meta.savedPosition = document.body.scrollTop;
      }
      return { x: 0, y: to.meta.savedPosition || 0 };
    }
  }
};

export const router = new Router(RouterConfig);

router.beforeEach(async (to, from, next) => {
  try {
    next();
  } catch (error) {}
});
