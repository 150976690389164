<template>
  <el-menu
    class="side-menu"
    :default-openeds="[
      'devtools-id',
      'devtools-crypto',
      'devtools-format-json',
    ]"
    :default-active="defaultActive"
    @select="handleSelect"
  >
    <el-submenu
      v-for="menu in sideMenu"
      :key="menu.groupId"
      :index="menu.groupId"
    >
      <template slot="title"
        ><i class="el-icon-menu"></i>{{ menu.groupName }}</template
      >
      <el-menu-item
        v-for="item in menu.items"
        :key="item.id"
        :index="item.id"
        >{{ item.name }}</el-menu-item
      >
    </el-submenu>
  </el-menu>
</template>

<script>
export default {
  data() {
    return {
      defaultActive: "id-uuid",
    };
  },
  props: {
    sideMenu: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    $route(to, form) {
      if (to.name) {
        this.defaultActive = to.name;
      }
    },
  },
  methods: {
    handleSelect(key, keyPath) {
      const p = keyPath[0].split("-")[0];
      this.$router.replace({ path: `/${p}/${key}` }).catch(() => {});
    },
  },
};
</script>

<style scoped>
.side-menu {
  height: calc(100vh - 60px);
}
</style>