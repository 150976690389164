export const routers = [
  {
    path: '/',
    redirect: '/devtools'
  },
  {
    path: '/devtools',
    component: () => import('../views/home/dev.vue'),
    children: [
      {
        path: '',
        redirect: 'id-uuid'
      },
      {
        path: 'id-uuid',
        name: 'id-uuid',
        meta: {
          title: 'uuid',
          keepAlive: true
        },
        component: () => import('../views/dev-tools/uuid.vue')
      },
      {
        path: 'crypto-md5',
        name: 'crypto-md5',
        meta: {
          title: 'md5',
          keepAlive: true
        },
        component: () => import('../views/dev-tools/md5.vue')
      },
      {
        path: 'crypto-sha',
        name: 'crypto-sha',
        meta: {
          title: 'sha',
          keepAlive: true
        },
        component: () => import('../views/dev-tools/sha.vue')
      },
      {
        path: 'crypto-hmac',
        name: 'crypto-hmac',
        meta: {
          title: 'hmac',
          keepAlive: true
        },
        component: () => import('../views/dev-tools/hmac.vue')
      },
      {
        path: 'crypto-aes',
        name: 'crypto-aes',
        meta: {
          title: 'aes',
          keepAlive: true
        },
        component: () => import('../views/dev-tools/aes.vue')
      },
      {
        path: 'crypto-des',
        name: 'crypto-des',
        meta: {
          title: 'des',
          keepAlive: true
        },
        component: () => import('../views/dev-tools/des.vue')
      },
      {
        path: 'crypto-pbkdf2',
        name: 'crypto-pbkdf2',
        meta: {
          title: 'pbkdf2',
          keepAlive: true
        },
        component: () => import('../views/dev-tools/pbkdf2.vue')
      },
      {
        path: 'format-json',
        name: 'format-json',
        meta: {
          title: 'json',
          keepAlive: true
        },
        component: () => import('../views/dev-tools/format-json.vue')
      }
    ]
  }
];
